// src/utils/trpc.ts
import { createTRPCNext } from "@trpc/next";
import { httpBatchLink, httpLink, loggerLink, splitLink } from "@trpc/client";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import type { AppRouter } from "@30p/api";
import { transformer } from "@30p/api/transformer";

const getBaseUrl = () => {
  if (typeof window !== "undefined") return ""; // browser should use relative url
  return ""; // ?
};

export const trpc = createTRPCNext<AppRouter>({
  config() {
    return {
      transformer,
      links: [
        loggerLink({
          enabled: (opts) =>
            opts.direction === "down" && opts.result instanceof Error,
        }),
        // httpBatchLink({
        //   url: `${getBaseUrl()}/api/trpc`,
        // }),
        splitLink({
          condition(op) {
            return op.context.batch === true;
          },
          // when condition is true, use normal request
          true: httpBatchLink({
            url: `${getBaseUrl()}/api/trpc`,
          }),
          // when condition is false, use batching
          false: httpLink({
            // url,
            url: `${getBaseUrl()}/api/trpc`,
          }),
        }),
      ],
    };
  },
  ssr: false,
});

/**
 * Inference helpers for input types
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helpers for output types
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>;
